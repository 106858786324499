import React from 'react';
import PropTypes from 'prop-types';
import StorePage from '../containers/StorePage/StorePage';

const NossosCursos = ({ location, pageContext }) => {
  return <StorePage location={location} pageContext={pageContext} />;
};

NossosCursos.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default NossosCursos;

import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Text from '../../components/ui/Text';
import Anchor from '../../components/ui/Anchor';
import Form, { FormGroup, Input, Radio, Select } from '../../components/ui/form';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import { CaseStudyWrapper } from '../courses/courses.style';
import CaseStudyBox from '../../components/box-large-image/layout-two';
import productsService from '../../services/productsService';
import { ButtonWrap, ButtonGroupWrap } from '../../components/ui/button/button.style';
import { RadioContainer } from '../../components/ResponsibleRadio/styles';
import Label from '../../components/ui/form/label/label';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import Alert from '../../components/ui/alert';
import { LoadingContainer } from '../../containers/site-selection/styles';
import PuffLoader from 'react-spinners/PuffLoader';
import { theme } from '../../theme';

const StorePage = ({
  location,
  pageContext,
  sectionTitleStyle,
  bottomTextStyle,
  bottomTextLinkStyle,
  caseStudyStyles,
}) => {
  const { storeJson } = useStaticQuery(graphql`
    query {
      storeJson {
        description
        title
      }
    }
  `);

  const { title, description } = storeJson;

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorie, setCategorie] = useState({});

  const handleChangeCourse = event => {
    setCategorie({ name: event.target.value });
  };

  useEffect(() => {
    productsService.products().then(response => {
      setProducts([...response.data]);
    });
    productsService.categories().then(response => {
      setCategories([...response.data]);
    });
  }, []);

  const filterProductsByCategorie = products.filter(product => {
    if (!categorie.name || categorie.name === undefined) {
      return true;
    }
    return product.categories.some(cat => cat.name === categorie.name);
  });

  return (
    <>
      <SEO title={title} description={description} />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title={title} />
      <CaseStudyWrapper>
        <Container>
          <Row>
            <Col lg={12}>
              <Select name="categorie" id="categorie" value={categorie.name} onChange={handleChangeCourse}>
                <option key="0" value="">
                  Todos os preparatórios
                </option>
                {categories.map(cat => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            {!categorie.name && products.length === 0 && (
              <Col lg={12}>
                <LoadingContainer>
                  <PuffLoader color={theme.colors.primary} size={60} />
                </LoadingContainer>
              </Col>
            )}
            {categorie.name && filterProductsByCategorie.length === 0 && (
              <Col lg={12}>
                <Alert align="center" variant="warning">
                  <>
                    <Text mb="0" fontWeight="bold">
                      Não existem cursos para a categoria selecionada
                    </Text>
                  </>
                </Alert>
              </Col>
            )}
            {filterProductsByCategorie.map(product => (
              <Col lg={4}>
                <div className="item" key={product.slug}>
                  <CaseStudyBox
                    {...caseStudyStyles}
                    imageSrc={product.images[0]?.src}
                    title={product.name}
                    category={product.categories[0]?.name}
                    path={product.permalink}
                    btnText="Saiba mais"
                  />
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={12}>
              <Text {...bottomTextStyle}>
                <Anchor {...bottomTextLinkStyle} path="https://loja.maismilitar.com.br" target="_blank">
                  Conheça mais cursos
                </Anchor>
              </Text>
            </Col>
          </Row>
        </Container>
      </CaseStudyWrapper>
      <Footer />
    </>
  );
};

StorePage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  sectionTitleStyle: PropTypes.object,
  bottomTextStyle: PropTypes.object,
  bottomTextLinkStyle: PropTypes.object,
  caseStudyStyles: PropTypes.object,
};

StorePage.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  bottomTextStyle: {
    fontSize: '18px',
    fontweight: 500,
    lineHeight: 1.4,
    color: '#333333',
    mt: '40px',
    align: 'center',
  },
  bottomTextLinkStyle: {
    fontWeight: 500,
    layout: 'underline',
    hover: {
      layout: 2,
    },
  },
  caseStudyStyles: {
    boxStyle: {
      mt: '20px',
      mb: '35px',
      ml: '15px',
      mr: '15px',
    },
    contentBoxStyle: {
      pt: '25px',
      pl: '26px',
      pr: '26px',
      textalign: 'left',
    },
    headingStyle: {
      as: 'h6',
      fontweight: 600,
      mb: '2px',
    },
    descStyle: {
      mb: 0,
      mt: '13px',
    },
  },
};
export default StorePage;
